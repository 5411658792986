<template>
  <KpiDivisiForm mode="Tambah" module="KPI"> </KpiDivisiForm>
</template>

<script>
import KpiDivisiForm from './form-kpi';

const KpiDivisiAdd = {
  name: 'KpiDivisiAdd',
  components: { KpiDivisiForm },
};

export default KpiDivisiAdd;
</script>
